%new-categories-nav-max-height {
	@if ($app-katalog){
		max-height: calc(100vh - 200px);
	}@else{
		max-height: calc(100vh - 150px);
	}
	.sticky & {
		@if ($app-katalog){
			max-height: calc(100vh - 150px);
		}@else{
			max-height: calc(100vh - 100px);
		}
	}
}
#headless-main-menu {
	.sticky & {
		position: fixed;
		left: 0;
		right: 0;
		@if($app-katalog){
			top: 56px;
		}@else{
			top: 8px;
		}
	}
	position: relative;
	box-sizing: border-box;
	height: 56px;
	@if ($app-katalog){
		background: #E6F1FF;
	}@else{
		background: #FFFFFF;
	}
	box-shadow: inset 0px -2px 0px 0px #E5E5E5;
	z-index: 4;
	.nav-content {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: 0 auto;
		height: 100%;
		@if($app-katalog){
			width: $breakpoint-large-width+px;
		} @else {
			width: 1218px;
			@media all and (min-width: 1440px) {
				width: 1380px;
			}
		}
		&::before {
			content: '';
			display: flex;
			height: 100%;
			width: 100%;
			position: absolute;
			top:0;
			right: calc(100% - 263px);
			background: $new-menu-bar-background;
		}
		@media all and (max-width: #{$breakpoint-large}px) {
			width: #{$breakpoint-midium-width}px;
			&::before {
				right: calc(100% - 234px);
			}
		}
	}
	#nav-toggle {
		position: relative;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		box-sizing: border-box;
		flex-wrap: nowrap;
		width: 259px;
		min-width: 259px;
		height: 48px;
		margin: 4px 4px 4px 0;
		border: 2px solid transparent;
		margin-right: auto;
		padding: 0;
		border-radius: 7px;
		background: $new-menu-bar-background;
		&::before {
			content: '';
			display: block;
			height: 56px;
			width: 100vw;
			position: absolute;
			top: -6px;
			right: calc(100% - 261px);
			z-index: 0;
			background: $new-menu-bar-background;
		}
		@media all and (max-width: #{$breakpoint-large}px) {
			width: 235px;
			min-width: 235px;
			&::before {
				right: calc(100% - 239px);
			}
		}
	
		&.active {
			.nav-toggle-text {
				&::after {
					transform: rotate(-180deg);
				}
			}
		}
		&:hover,
		&.active {
			background: $new-menu-bar-active-background;
			&::before {
				background: $new-menu-bar-active-background;
			}
		}
		&:focus-visible {
			border: transparent;
			.nav-toggle-text {
				border: 2px solid #FFFFFF;
			}
		}
		.nav-toggle-text {
			position: relative;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			box-sizing: border-box;
			flex-wrap: nowrap;
			height: 100%;
			width: 100%;
			border: 2px solid transparent;
			border-radius: 7px;
			text-transform: initial;
			font-size: 16px;
			color: #FFFFFF;
			background: transparent;
			&::before {
				@extend %icon-menu-hamburger-alt;
				margin-right: 8px;
				font-size: 24px;
			}
			&::after {
				@extend %icon-navigation-arrow-down;
				margin-left: 8px;
				font-size: 24px;
			}
		}
	}
}
#usp-menu {
	display: flex;
	align-items: stretch;
	align-content: center;
	justify-content: space-between;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	padding-left: 64px;
	padding-bottom: 1px;
	max-width: calc(100% - 263px);
	overflow: hidden;
	@media screen and (max-width: #{$breakpoint-large}px){
		padding-left: 24px;
		max-width: calc(100% - 239px);
	}
	li {
		display: flex;
		align-items: stretch;
		height: 100%;
		justify-content: flex-end;
		max-width: 25%;
	}
	.usp-link {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		color: #0A0A0A;
		max-width: 100%;
		span {
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.usp-image {
			display: flex;
			flex: 0;
			margin-right: 8px;
			width: 24px;
			min-width: 24px;
			height: 24px;
			min-height: 24px;
			background-size: cover;
			@media screen and (max-width: #{$breakpoint-large}px){
				display: none;
			}
		}
	}
}
#account-area-toggle,
#headless-main-menu .static-link {
	display: flex;
	height: 100%;
	a {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		font-weight: bold;
		font-size: 14px;
		color: #0A0A0A;
	}
}
#account-area-toggle {
	a {
		min-width: 110px;
		&::after {
			@extend %icon-navigation-arrow-down;
			font-size: 24px;
		}
		&.active::after {
			transform: rotate(-180deg);
		}
	}
	&:hover {
		a {
			text-decoration: none;
		}
	}
}
#account-area-menu {
	&.active {
		display: block;
	}
	display: none;
	position: absolute;
	box-sizing: border-box;
	top: 100%;
	right: 0;
	background: #FFFFFF;
	max-width: 1152px;
	width: 1152px;
	@media all and (max-width: #{$breakpoint-large}px) {
		max-width: 896px;
		width: 896px;
	}
	@media all and (min-width: 1440px) {
		max-width: 1312px;
		width: 1312px;
	}
	@extend %new-categories-nav-max-height;
	padding: 0 24px 24px;
	overflow-y: auto;
}
#categories-menu {
	&.active {
		display: flex;
	}
	display: none;
	position: absolute;
	box-sizing: border-box;
	top: 100%;
	left: 0;
	background: #F1F1F1;
	max-width: calc(100% - 67px);
	@extend %new-categories-nav-max-height;
	padding: 0;
}
#categories-nav {
	box-sizing: border-box;
	width: 263px;
	min-width: 263px;
	height: 100%;
	padding: 16px 0;
	overflow-y: auto;
	background: #F1F1F1;
	@extend %new-categories-nav-max-height;
	@media all and (max-width: #{$breakpoint-large}px) {
		width: 239px;
		min-width: 239px;
	}

	.nav-category-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		box-sizing: border-box;
		min-height: 40px;
		max-width: 100%;
		padding: 8px;
		color: #0A0A0A;
		font-size: 14px;
		line-height: 19px;
		cursor: pointer;
		span {
			margin-right: 5px;
			max-width: 100%;
			overflow: hidden;
			// text-overflow: ellipsis;
			// white-space: nowrap;
		}

		&::after {
			@extend %icon-navigation-arrow-down;
			transform: rotate(-90deg);
			font-size: 24px;
			margin-left: auto;
			text-align: center;
		}
		&.active {
			background: #FFFFFF;
			padding-right: 24px;
			&::after {
				display: none;
			}
		}
	}
	.nav-category-image {
		display: flex;
		flex: 0;
		margin-right: 8px;
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
		background-size: cover;
	}
}
#nav-categories-content {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	background: #FFFFFF;
	&::after{
		content: '';
		position: absolute;
		bottom: 0; left: 0;
		display: block;
		width: 100%;
		height: 24px;
		background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
	}
	.nav-category {
		position: relative;
		display: none;
		flex-direction: column;
		height: 100%;
		@extend %new-categories-nav-max-height;
		padding: 0 24px 24px 24px;
		box-sizing: border-box;
		overflow-y: auto;
		&.active {
			display: flex;
		}
	}
	.nav-category-header {
		position: sticky;
		top: 0;
		display: flex;
		align-items: baseline;
		justify-content: flex-start;
		box-sizing: border-box;
		width: 100%;
		padding-top: 24px;
		padding-bottom: 8px;
		background: #FFFFFF;
		border-bottom: 1px solid #E5E5E5;
		&::after{
			content: '';
			position: absolute;
			top: calc(100% + 1px); left: 0;
			display: block;
			width: 100%;
			height: 16px;
			background-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
		}
	}
	.nav-category-title {
		font-size: 20px;
		font-weight: bold;
		color: $text-color;
	}
	.nav-category-link,
	.nav-category-outlet-link {
		margin-left: 16px;
		font-size: 14px;
		color: #016EF4;
	}
	.nav-category-outlet-link {
		margin-left: auto;
	}
	.nav-category-content {
		display: grid;
		grid-gap: 0 16px;
		grid-template-columns: repeat(5,1fr);
		+ .nav-category-banner {
			padding-top: 24px;
		}
	}
	.nav-category-banner {
		max-width: 100%;
		max-height: 100px;
		margin-top: auto;
		img {
			max-width: 100%;
		}
	}
	.nav-subcategory {
		padding-top: 24px;
	}
	.nav-subcategory-title {
		margin-bottom: 12px;
		a, span {
			font-size: 16px;
			line-height: 21px;
			font-weight: bold;
			color: #016EF4;
		}
	}
	.nav-subcategory-list {
		li {
			margin-bottom: 8px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			font-size: 14px;
			line-height: 19px;
			color: $text-color;
		}
	}
}

.sticky #top:not(.top2) {
	#nav-toggle-sticky {
		display: flex;
	}
}

#nav-toggle-sticky {
	position: relative;
	top: 14px;
	display: none;
	align-self: stretch;
	align-content: center;
	align-items: center;
	margin-left: -2px;
	padding: 3px 6px;
	color: #FFFFFF;
	background: transparent;
	border-radius: 7px;
	border: 2px solid transparent;
	font-size: 16px;
	text-transform: initial;
	@if($app-katalog){
		background: $new-menu-bar-background;
	}
	@if($app-ole){
		color: $text-color;
	}
	&::before{
		@extend %icon-menu-hamburger-alt;
		margin-right: 8px;
		font-size: 24px;
	}
	&.active {
		@if($app-katalog){
			background: $new-menu-bar-active-background;
		}@else{
			color: #0A0A0A;
			background: #FFFFFF;
		}
		&::before{
			@extend %icon-close2;
			font-size: 24px;
		}
	}
	&:hover {
		color: #FFFFFF;
		@if($app-katalog){
			background: $new-menu-bar-active-background;
		}@else{
			@if($app-ole){
				background: $new-menu-bar-background;
			}@else{
				background: #0A0A0A;
			}
		}
	}
	&:focus-visible {
		border-color: #FFFFFF;
	}
	@media all and (max-width: #{$breakpoint-large}px) {
		top: 12px;
		padding: 4px 8px;
		&::before {
			margin-right: auto;
		}
		.nav-toggle-text {
			display: none;
		}
	}
}