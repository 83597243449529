.product-energy {
	margin-top: 9px;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	.energy-label.no-url{
		pointer-events: none;
	}
	.energy-label,
	.energy-card {
		display: flex;
		align-content: center;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
	}
	&.product-energy-old.product-energy-has-card {
		.energy-card{
			line-height: 15px;
		}
	}
	&.product-energy-new.product-energy-has-card {
		.energy-card{
			line-height: 16px;
		}
	}
	&.energy-label-big {
		min-width: 160px;
		.energy-label {
			width: 76px;
			min-width: 76px;
			height: 40px;
			min-height: 40px;
			&:before {
				width: 80px;
				height: 34px;
				top: 3px;
				background-size: contain;
			}
			&:after {
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
			}
		}
		.scale-a_g_v2 {
			width: 70px;
			min-width: 70px;
			&::before{
				width: 44px;
				left:12px;
			}
			&::after {
				height: 38px;
				background-size: auto 33px;
				border: 0;
				background: #fff url(/img/energy/A-G_new-medium.svg) no-repeat center center;
			}
			svg {
				width: 70px;
				height: 40px;
			}
		}
	}
	.old-label{
		font-weight: bold;
		color:$text-color;
		display: flex;
		align-items: center;
		align-content: center;
		.info{
			display: inline-flex;
			padding: 0;
			&:before{
				margin: 0 0 0 3px;
				@extend %icon-delivery-info;
			}
		}
	}
	.energy-card {
		a, span{
			font-size: 12px;
			color: $text-color-lighter;
			font-style: normal;
			padding:0 10px 0 0;
		}
		span{
			color:$text-color;
			font-weight: bold;
		}
		height: 30px;
		line-height:30px;
		span{
			display: block;
		}
	}
}
#modal-energy-img{
	width:460px;
	max-width: 460px;
	padding:0;
	min-height:300px;
	text-align:center;
	position:relative;
	.remodal-close{
		z-index:3;
		right: -37px;
	}
	img{
		display:block;
		margin:0 auto;
		position:relative;
		z-index:2;
	}
	em{
		@extend %loading;
		width:100%;
		height:270px;
		position:absolute;
		margin:0 auto;
		z-index: 1;
		top:0;
		left:0;
	}
}

.energy-label {
	color: #fff;
	font-size: 26px;
	font-weight: bold;
	margin-right: 25px;
	line-height: 30px;
	text-align: center;
	position: relative;
	display: inline-block;
	width: 56px;
	min-width: 56px;
	height: 32px;
	min-height: 32px;

	&.with-energy-table{
		cursor:pointer;
		pointer-events: initial;
	}

	&:hover {
		color: #fff;
		text-decoration: none;
	}

	&:after {
		content: '';
		border-right-color: inherit;
		display: inline-block;
		width: 0;
		height: 0;
		border-top: 16px solid transparent;
		border-bottom: 16px solid transparent;
		border-right-width: 13px;
		border-right-style: solid;
		position: absolute;
		left: -13px;
		top: 0;
	}
	&:before {
		box-sizing: content-box !important;
		display: block;
		background: transparent no-repeat center center;
		content:'' !important;
		top:0px;
		left:0px;
		width:54px;
		height: 32px;
		position: absolute;
		z-index: 1;
	}
	&:not(.scale-a_g_v2) {
		margin-right: 12px;
		margin-left: 13px;
	}


	&.a:before {
		background-image: url(/img/energy/a_old.svg);
	}
	
	&.ap:before {
		background-image: url(/img/energy/ap_old.svg);
	}
	
	&.app:before {
		background-image: url(/img/energy/app_old.svg);
	}
	
	&.appp:before {
		background-image: url(/img/energy/appp_old.svg);
	}
	
	&.b:before {
		background-image: url(/img/energy/b_old.svg);
	}
	
	&.c:before {
		background-image: url(/img/energy/c_old.svg);
	}
	
	&.d:before {
		background-image: url(/img/energy/d_old.svg);
	}
	
	&.e:before {
		background-image: url(/img/energy/e_old.svg);
	}
	
	&.f:before {
		background-image: url(/img/energy/f_old.svg);
	}
	
	&.g:before {
		background-image: url(/img/energy/g_old.svg);
	}
}
.scale-a_g.a,
.scale-ap_f.ap,
.scale-app_e.app,
.scale-appp_d.appp,
.scale-appp_g.appp {
	background-color: #00a651;
	border-right-color: #00a651;
}
.scale-a_g.b,
.scale-ap_f.a,
.scale-app_e.ap,
.scale-appp_d.app,
.scale-appp_g.app {
	background-color: #4db848;
	border-right-color: #4db848;
}
.scale-a_g.c,
.scale-ap_f.b,
.scale-app_e.a,
.scale-appp_d.ap,
.scale-appp_g.ap {
	background-color: #bed62f;
	border-right-color: #bed62f;
}
.scale-a_g.d,
.scale-ap_f.c,
.scale-app_e.b,
.scale-appp_d.a,
.scale-appp_g.a {
	background-color: #fff200;
	border-right-color: #fff200;
}
.scale-a_g.e,
.scale-ap_f.d,
.scale-app_e.c,
.scale-appp_d.b,
.scale-appp_g.b {
	background-color: #fdb813;
	border-right-color: #fdb813;
}
.scale-a_g.f,
.scale-ap_f.e,
.scale-app_e.d,
.scale-appp_d.c,
.scale-appp_g.c {
	background-color: #f36f21;
	border-right-color: #f36f21;
}
.scale-a_g.g,
.scale-ap_f.f,
.scale-app_e.e,
.scale-appp_d.d,
.scale-appp_g.d,
.scale-appp_g.e,
.scale-appp_g.f,
.scale-appp_g.g {
	background-color: #ed1c24;
	border-right-color: #ed1c24;
}

.scale-a_g_v2{
	height: 32px;
	width: 56px;
	margin-right: 8px;
	line-height: 31px;
	box-sizing: border-box !important;
	*{
		box-sizing: border-box !important;
	}
	&:before{
		box-sizing: content-box !important;
		display: block;
		position: absolute;
		background:no-repeat center center;
		content:"" !important;
		height: 30px;
		top:1px;
		width:32px;
		left:9px;
		z-index: 1;
	}
	&.a:before{
		background-image: url(/img/energy/A_new.svg);
	}
	&.b:before{
		background-image: url(/img/energy/B_new.svg);
	}
	&.c:before{
		background-image: url(/img/energy/C_new.svg);
	}
	&.d:before{
		background-image: url(/img/energy/D_new.svg);
	}
	&.e:before{
		background-image: url(/img/energy/E_new.svg);
	}
	&.f:before{
		background-image: url(/img/energy/F_new.svg);
	}
	&.g:before{
		background-image: url(/img/energy/G_new.svg);
	}
	&:after{
		position:absolute;
		border:0;
		top:1px;
		left: auto;
		right: 1px;
		width: 14px;
		height: 30px;
		z-index: 1;
		content:"";
		background: #fff url(/img/energy/A-G_new.svg) no-repeat center center;
	}
	svg {
		transform: scaleX(-1);
	}
}
.scale-a_g_v2.a{
	svg path{
		fill:#009d3d;
	}
}
.scale-a_g_v2.b{
	svg path{
		fill:#68bb25;
	}
}
.scale-a_g_v2.c{
	svg path{
		fill:#cddc00;
	}
}
.scale-a_g_v2.d{
	svg path{
		fill:#fff100;
	}
}
.scale-a_g_v2.e{
	svg path{
		fill:#f8bf00;
	}
}
.scale-a_g_v2.f{
	svg path{
		fill:#eb760d;
	}
}
.scale-a_g_v2.g{
	svg path{
		fill:#df271e;
	}
}
