.menu-open {
	body {
		overflow: hidden;
		&:before {
			content: "";
			background: rgba(43,46,56,.9);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 4;
		}
	}
}