.recaptcha {
	margin: 10px 0;
	width: 304px;

	&[hidden] {
		display: none;
	}

	&.is-centered {
		margin-left: auto;
		margin-right: auto;
	}

	.error-message {
		color: #ED1C24;
		font-weight: bold;
		margin-top: 6px;
		background: #ffcccc;
		padding: 10px;
	}

	.info {
		font-size: 12px;
		margin-bottom: 10px;
	}
	#g-recaptcha-response-error {
		color: #ED1C24;
		margin-top: 6px;
		background: #ffcccc;
		padding: 10px;
		display: block;
	}
}