@import '../style/modal-product-details';
@import '../style/product-opinion-stars-grades';

#accessory-crossselling {
	margin: -1px 0 0;
	min-height: 400px !important;
	.accessory-groups {
		overflow: hidden;
		top: 1px;
		position: relative;
		z-index: 1;
		ul {
			display: flex;
			align-items: end;
			list-style: none;
			margin: 0;
			padding: 0;
			transition: all 0.3s;
			.accessory-group:first-child() {
				border-left: 1px solid $border-color;
			}
			&[data-overflowed-elements="0"]{
				~ .slide-right{
					pointer-events: none;
					visibility: hidden;
				}
			}
	
			&[data-scroll] ~ .slide-left{
				visibility: visible;
			}
		}
		.slide-right, .slide-left{
			align-items: flex-end;
			button{
				margin-bottom:2px;
			}
		}
	}

	.accessory-group {
		background: #e5e5e5;
		position: relative;
		font-size: 16px;
		font-weight: bold;
		padding: 0 24px 0 16px;
		height: 53px;
		line-height: 50px;
		white-space: nowrap;
		border: 1px solid $border-color;
		border-left: 0;
		cursor: pointer;
		&:after {
			content: '';
			background: #fff;
			border-radius: 50%;
			position: absolute;
			top: 8px;
			right: 8px;
			height: 10px;
			width: 10px;
			border: 2px solid #016ef4;
		}
		&.active {
			background: #fff;
			cursor: default;
			height: 69px;
			line-height: 69px;
			border-bottom: 0;
			border-left: 1px solid $border-color;
			box-shadow: 0px 2px 7px 0px #28292a1a;

			&:after {
				background: #016ef4;
				@extend %icon-check;
				text-align: center;
				font-size: 8px;
				border: 0;
				height: 14px;
				line-height: 14px;
				width: 14px;
				color: #fff;
			}
		}
		&.prev-active {
			border-right: 0;
		}
	}

	.accessory-lists {
		position: relative;
		border: 1px solid $border-color;
		padding: 16px 16px 5px;
		background: #fff;
	}

	.accessory-list {
		display: none;
		&.active {
			display: block;
			&[data-overflowed-elements="0"],
			&:not([data-overflowed-elements]){
				~ .slide-right{
					pointer-events: none;
					visibility: hidden;
				}
			}
			&[data-scroll] ~ .slide-left{
				visibility: visible;
			}
		}
		overflow-x: hidden;
		position: relative;

	}
	.accessory-box {
		padding-bottom: 10px;
		display: flex;
		transition: all 0.3s;
	}

	&.loading {
		@extend %loading;
	}

	.accessory-item {
		border: 1px solid $border-color;
		padding: 10px 16px 16px;
		width: 217px;
		min-width: 217px;
		margin: 0 8px 0 0;
		border-radius: 0;
		box-shadow: none;
		background: transparent;
		overflow: hidden;
		&.accessory-item-img{
			padding:0;
			border:0;
			img{
				width: 100%;
				height:100%;
				display: block;
			}
		}
	}
	.accessory-photo {
		height: 168px;
		display: flex;
		align-items: center;
		width: 100%;
		margin: 0 auto;
	}
	.accessory-name {
		margin-top: 16px;
		a {
			font-weight: bold;
			font-size: 14px;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			color: $text-color;
		}
	}
	.product-opinion-stars-grades {
		margin-top: 8px;
		height:18px;
	}
	.product-attributes {
		margin: 16px 0 0;
		.attributes-row {
			line-height: 18px;
			font-size: 14px;
			position: relative;
			padding: 0 0 0 30px;
			&:before {
				content: '';
				position: absolute;
				top: 8px;
				left: 11px;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				display: block;
				background: #afafaf;
			}
			+ .attributes-row {
				margin-top: 6px;
			}
		}
		.attribute-name {
			font-size: 14px;
			color: #767676;
			a {
				font-size: 14px;
				display: inline;
				border: 0;
				color: #767676;
			}
		}
		.attribute-value {
			font-size: 14px;
			display: inline;
			font-weight: normal;
		}
	}
	.accessory-price {
		float: left;
		margin-top: 13px;
		.price-normal {
			font-size: 20px;
		}
	}
	.price-omnibus{
		text-align: left;
		padding-top: 8px;
		clear: both;
	}
	.accessory-to-cart {
		float: right;
		box-shadow: 0px 1px 3px 0px #30313333;
		background: #016ef4;
		border-radius: 100px;
		border: 0;
		color: #fff;
		display: inline-block;
		font-size: 14px;
		font-weight: bold;
		width: 87px;
		text-align: center;
		height: 40px;
		line-height: 40px;
		padding: 0;
		text-transform: none;
		margin: 10px 0 0;
		&:before {
			content: attr(data-unchecked);
		}
		&:hover {
			text-decoration: none;
		}
		&.added-to-cart {
			background: #fff;
			color: #016ef4;
			box-shadow: 0px 3px 6px 0px #3031331f, 0px 1px 2px 0px #30313329, 0px 0px 0px 1px #e5e5e5 inset;
			&:before {
				content: attr(data-checked);
			}
		}
	}

	.slide-left, .slide-right{
		position: absolute;
		top:0;
		height:calc(100% - 1px);
		display: flex;
		align-items: center;
		padding: 0 8px;
		button{
			width:48px;
			cursor: pointer;
			height:48px;
			display: block;
			background-color: #fff;
			border-radius: 100%;
			box-shadow: 0px 4px 8px 0px #30313329, 0px 1px 3px 0px #30313333, 0px 0px 0px 1px #E5E5E5 inset;
			color:$link-color;
			padding:0;
			border:0;
			&:before{
				@extend %icon-arrow;
			}
		}
	}
	.slide-left{
		visibility: hidden;
		background: linear-gradient(90deg, #FFFFFF 0.13%, rgba(255, 255, 255, 0) 99%);
		left:0;
		button:before{
			transform: rotate(-180deg);
		}
	}
	.slide-right{
		right:0;
		background: linear-gradient(270deg, #FFFFFF 0.13%, rgba(255, 255, 255, 0) 99%);
	}
}
