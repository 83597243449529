@media only print {
	#ear-box,
	#promotion-bar,
	.accessories-list,
	.add-to-cart {
		display: none !important;
	}

	#compare-bar {
		display: none !important;
	}

	#scroll-top,
	#see-recommended,
	#top,
	.banners,
	.rotator,
	.social-box,
	footer {
		display: none !important;
	}

	#breadcrumb::after {
		display: none !important;
	}

	.product-box {
		overflow: visible !important;

		#miniatures {
			display: none !important;
		}
	}

	.advertising-placement {
		display: none !important;
	}

	#description {
		#description-content,
		#description-promotion,
		#expand-description,
		#similar-products,
		#toogle-tech-details,
		.product-criteria,
		.product-description,
		.report-bug {
			display: none !important;
		}

		h4 {
			margin-top: 0;
		}

		#description-tech-content.has-basic-tech-details.has-full-tech-details {
			#basic-tech-details,
			.basic-tech-details {
				display: none !important;
			}

			#full-tech-details,
			.full-tech-details {
				display: block !important;
			}
		}
	}

	#product-card {
		#header,
		#menu-product,
		#opinion-banner,
		#product-for-sale {
			display: none !important;
		}
	}

	.bottom-bar-container,
	.community-list,
	.energy-card {
		display: none !important;
	}

	#breadcrumb {
		display: block !important;
	}

	#big-photo {
		pointer-events: none;
		user-select: none;
	}

	#product-tabs {
		.product-tab-content {
			padding: 0 !important;
		}

		.product-tab-akcesoria,
		.product-tab-gwarancje,
		.product-tab-pytania,
		.product-tab-raty,
		.product-tab-uslugi,
		.product-tab-zestawy {
			display: none !important;
		}

		.product-tab-opinie-empty {
			display: none !important;
		}

		.product-tab-header {
			display: none !important;

			&[data-tab="esd-code"],
			&[data-tab="opinie"] {
				display: block !important;
			}
		}

		#add-opinion-form,
		#opinion-filter,
		#opinion-list,
		.opinion-buttons,
		.paging {
			display: none !important;
		}

		.product-tab-print,
		.product-tab-print * {
			display: block !important;
		}
	}

	.product-sales {
		.product-sales-print-logo {
			display: block !important;
		}

		.product-sales-print-info {
			display: block !important;
		}
	}

	body {
		padding-top: 0 !important;
	}
}
